import NotificationClass from "../typescript/classes/NotificationClass";
import { NotificationTypes } from "../typescript/enums/enums";

export interface IApiError {
  errorCode?: string;
  field?: string;
  message?: string;
}

export interface IApiErrorResponse {
  code: string;
  message?: string;
  errors: IApiError[];
}

export class ApiError implements IApiError {
  errorCode: string;
  field?: string;
  message: string;

  constructor(data: Partial<IApiError> = {}) {
    this.errorCode = data.errorCode ?? "Okänd felkod";
    this.field = data.field ?? undefined;
    this.message = data.message ?? "Okänt fel";
  }
}

export class ApiErrorResponse implements IApiErrorResponse {
  code: string;
  message: string;
  errors: ApiError[];

  constructor(data: Partial<IApiErrorResponse> = {}) {
    this.code = data.code ?? "Okänd felkod";
    this.message = data.message ?? "Okänt fel";
    this.errors = data.errors?.map((error) => new ApiError(error)) ?? [];
  }
}

export const isApiError = (data: any): data is ApiError => {
  return (
    data !== null &&
    typeof data === "object" &&
    (typeof data.field === "string" || data.field == null) &&
    typeof data.message === "string"
  )
}

const isApiErrorResponse = (data: any): data is ApiErrorResponse => {
  return (
    data !== null &&
    typeof data === "object" &&
    typeof data.code === "string" &&
    typeof data.message === "string" &&
    Array.isArray(data.errors) &&
    data.errors.every((error: any) => isApiError(error))
  );
};

function handleError (error: any) {
  let message: string;
  let isHtml: boolean = false;

  if (isApiErrorResponse(error?.response?.data)) {
    // Handle ApiErrorResponse
    const apiResponse: ApiErrorResponse = error.response.data;

    if (apiResponse.errors.length === 1) {
      message = apiResponse.errors[0].message ?? "";
    } else {
      isHtml = true;
      message = apiResponse.message + "<ul>";

      for (const key in apiResponse.errors) {
        message += `<li>${apiResponse.errors[key].message}</li>`;
      }

      message += "</ul>";
    }
  } else if (error?.isAxiosError) {
    // Handle AxiosError
    message = error.response?.data?.message || "Ett nätverksfel inträffade.";
  } else if (error instanceof Error) {
    // Handle general JavaScript errors
    message = error.message || "Ett oväntat fel inträffade. Försök igen senare.";
  } else {
    // Fallback for unknown error types
    message = "Ett okänt fel inträffade. Kontakta Bokinfo om problemet kvarstår.";
  }

  NotificationClass.createNotification({
    type: NotificationTypes.Error,
    message: message,
    isHtml: isHtml,
  });
}

export const useApiErrorHandler = () => handleError;