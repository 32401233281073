import { Alert } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Notification } from '../../redux/types/types';
import { removeNotification } from '../../redux/reducers/notificationsSlice.reducer';
import { NotificationTypes } from '../../typescript/enums/enums';
import DOMPurify from 'dompurify';

export const NotificationsComponent = () => {
	const list = useAppSelector((state) => state.notifications.value);
	const dispatch = useAppDispatch();

	const resolveIcon = (type: string): string | void => {
		if (type === NotificationTypes.Error) {
			return 'exclamation-circle';
		} else if (type === NotificationTypes.Warning) {
			return 'question-circle';
		} else if (type === NotificationTypes.Success) {
			return 'check-circle';
		} else if (type === NotificationTypes.Info) {
			return 'info-circle';
		}
	};

	return (
		<div className="notifications">
			{list?.length > 0 &&
				list.map((notification: Notification) => (
					<Alert
						className="notifications__notification"
						key={notification.id}
						variant={notification.type}
						onClick={() => dispatch(removeNotification(notification.id ?? -1))}
						dismissible
					>
						<i className={`bi me-3 bi-${resolveIcon(notification.type)}`} />
						{notification.isHtml ? (
							<div
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(notification.message),
								}}
							/>
						) : (
							notification.message
						)}						
					</Alert>
				))}
		</div>
	);
};
